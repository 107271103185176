import React, { useEffect, useState } from "react";
import LayoutOne from "../../layouts/LayoutOne";
import { ThirdwebNftMedia, useAddress, useContract, useOwnedNFTs, useBalance, getOwnedTokenIds } from "@thirdweb-dev/react";
import { CONTRACT_ADDRESS, CONTRACT_ADDRESS_COMIC } from "../../const/addresses";
import meta from '../../metadata.json'

console.log('metadata:', meta)

const Profile = () => {
  // const [ownedNFTs, setOwnedNFTs] = useState()
  // const [isOwnedNFTsLoading, setIsOwnedNFTsLoading] = useState()
  // const [comicError, setcomicError] = useState()
  // const [ownedNFTsComic, setOwnedNFTsComic] = useState()
  // const [isOwnedNFTsLoadingComic, setIsOwnedNFTsLoadingComic] = useState()
  const [truncateAddress, setTruncateAddress] = useState()  
  

  


  const address = useAddress();

  // const truncateAddress = (address) => {
  //   return `${address.slice(0, 6)}...${address.slice(-4)}`;
  // };

  const {
    contract
  } = useContract(CONTRACT_ADDRESS);

  const {
    data: ownedNFTs,
    isLoading: isOwnedNFTsLoading,
  } = useOwnedNFTs(contract, address);

  const {
    contract:comicContract,
    error:comicError
  } = useContract(CONTRACT_ADDRESS_COMIC, 'nft-drop');

  const {
    data: ownedNFTsComic,
    isLoading: isOwnedNFTsLoadingComic,
  } = useOwnedNFTs(comicContract, address);

  // const {
  //   data: getOwnedTokenIds,
  //   isLoading: getOwnedTokenIds,
  // } = useBalance(contract, address);

  useEffect(() => {
    const truncateAddress = (address) => {
      return `${address.slice(0, 6)}...${address.slice(-4)}`;
    };

    //setTruncateAddress(truncateAddress)
    
  }, [address])

  // const ownedTokenIds = contract.erc721.getOwnedTokenIds(
  //   "{{address}}",
  // );
  // console.log('ownedNFTsComic', comicContract?.erc20?.get())
  // console.log('ownedNFTsComic', comicError)
  return (
    <LayoutOne>
      <main className="fix">

        <div className="container">
          {address ? (
            <div>
              <div>
                <h1>Profile</h1>
                <p>Wallet Address: {truncateAddress}</p>
                {/* <p>Owned Treydorex Tokens:{getOwnedTokenIds}</p> */}
              </div>
              <hr />
              <div className="container-fluid">
                <h3>My Koraxos Collection</h3>
                <div className="row">
                  {!isOwnedNFTsLoading ? (
                    ownedNFTs?.length > 0 ? (
                      ownedNFTs?.map((nft) => (
                        <div className="col-md-4" key={nft.metadata.id}>
                          <h3 className="text-center">{nft.metadata.name}</h3>
                          <ThirdwebNftMedia
                            metadata={nft.metadata}
                          />

                          <h3 style={{ textIndent: "50px" }}>{nft.metadata.description}</h3>

                          <table>

                            {nft.metadata.attributes.map((obj, idx) => <tr key={idx}><td style={{ paddingRight: '1rem' }}>{obj.trait_type}</td><td> {obj.value}</td></tr>)}
                          </table>

                        </div>
                      ))
                    ) : <p>No NFT's found</p>
                  ) : <p>Loading...</p>}
                </div>
                <h3>My Koraxos Comic Collection</h3>
                <div className="row">
                  {!isOwnedNFTsLoadingComic ? (
                    ownedNFTsComic?.length > 0 ? (
                      ownedNFTsComic?.map((nft) => (
                        <div className="col-md-4" key={nft.metadata.id}>
                          <h3 className="text-center">{nft.metadata.name}</h3>
                          <ThirdwebNftMedia
                            metadata={nft.metadata}
                          />

                          <h3 style={{ textIndent: "50px" }}>{nft.metadata.description}</h3>

                          {/* <pre>{JSON.stringify(meta.find(item => item.name === nft.metadata.name))}</pre> */}

                          <table>
                            {/* {meta?.find(item => item.name === nft.metadata.name)?.attributes
                             ?.map(obj => <tr><td style={{paddingRight:'1rem'}}>{obj.trait_type}</td><td> {obj.value}</td></tr>)
                            } */}
                            {nft?.metadata?.attributes
                              ?.map((obj, idx) => {return <tr key={idx}><td style={{ paddingRight: '1rem' }}>{obj.trait_type}</td><td> {obj.value}</td></tr>})}
                          </table>

                        </div>
                      ))
                    ) : <p>No NFT's found</p>
                  ) : <p>Loading...</p>}
                </div>

              </div>
            </div>
          ) : (
            <div>
              <p>Please connect your wallet</p>
            </div>
          )}
        </div>




      </main>
    </LayoutOne>
  );
};

export default Profile;